import React from 'react';
import HelpCenterComponent from '../components/HelpCenter/HelpCenter';

const HelpCenter = () => {
    return (
        <div className="help-center-container">
            <HelpCenterComponent />
        </div>
    );
};

export default HelpCenter; 