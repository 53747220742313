import React, { useState } from 'react';
import { Button } from "@nextui-org/react";
import { IoAdd } from "react-icons/io5";

const APIKeys = () => {
    const [apiKeys, setApiKeys] = useState([
        {
            name: 'Server Key 1',
            type: 'Full Access',
            key: '23eaf7f0-f4f7-495e-8b86-fad3261282ac',
            created: 'Created on 28 Apr 2020, 18:20 GTM+4:10'
        },
        {
            name: 'Server Key 2',
            type: 'Read Only',
            key: 'bb98e571-a2e2-4de8-90a9-2e231b5e99',
            created: 'Created on 12 Feb 2020, 10:30 GTM+2:30'
        }
    ]);

    return (
        <div className="bg-[#2d363d] rounded-lg p-5 pb-8">
            <div className="flex items-center justify-between">
                <div>
                    <h1 className="text-white text-[16px] mb-2">API Keys</h1>
                    <p className="text-sm">Manage your API keys</p>
                </div>
                <Button 
                    className="bg-transparent border border-[#a3a3a3] text-[#a3a3a3]"
                    startContent={<IoAdd size={20} />}
                >
                    Create New Key
                </Button>
            </div>

            <div className="mt-8">
                {apiKeys.map((key, index) => (
                    <div key={index} className="border-b border-[#404B51] py-4">
                        <div className="flex items-center justify-between mb-2">
                            <div>
                                <h3 className="text-white text-sm mb-1">{key.name}</h3>
                                <span className="text-xs bg-[#404B51] px-2 py-1 rounded">
                                    {key.type}
                                </span>
                            </div>
                            <div className="flex items-center gap-2">
                                <Button size="sm" className="bg-transparent border border-[#a3a3a3] text-[#a3a3a3]">
                                    Edit
                                </Button>
                                <Button size="sm" className="bg-transparent border border-[#a3a3a3] text-[#a3a3a3]">
                                    Delete
                                </Button>
                            </div>
                        </div>
                        <div className="bg-[#1E252B] p-3 rounded mt-2">
                            <p className="text-sm font-mono">{key.key}</p>
                            <p className="text-xs mt-1 text-[#a3a3a3]">{key.created}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default APIKeys; 