import React from 'react'
import CompetitorHome from '../components/Competitors/CompetitorHome'

const Competitors = () => {
  return (
    <div className="competitors-container flex-1 p-4 bg-[#1e252b] min-h-screen">
      <h1 className="text-2xl text-white mb-4">Competitors</h1>
      <CompetitorHome />
    </div>
  )
}

export default Competitors
