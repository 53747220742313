import React, { useState } from 'react';
import { BiPlus } from 'react-icons/bi';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { LuDot } from 'react-icons/lu';
import { BsThreeDotsVertical } from 'react-icons/bs';
import CustomTable from '../components/CustomTable';
import Searchbar from '../components/Searchbar';
import FilterSelector from '../components/Dashboard/FilterSelector';

const MyCatalogs = () => {
    const [data, setData] = useState(generateDummyData());

    const toggleActive = (index) => {
        setData(prevData => {
            const newData = [...prevData];
            newData[index].isActive = !newData[index].isActive;
            newData[index].status = newData[index].isActive ? 'Enabled' : 'Disabled';
            return newData;
        });
    };

    function generateDummyData() {
        const catalogs = [{ name: 'Catalog 1', products: '150', lastUpdate: '2 days ago', status: 'Enabled', isActive: true }];
        const data = [];
        for (let i = 0; i < 5; i++) {
            const catalog = catalogs[i % catalogs.length];
            data.push({
                ...catalog,
                id: i,
            });
        }
        return data;
    }

    const columns = [
        {
            label: 'Name',
            field: 'name',
            sortable: true,
        },
        {
            label: 'Products',
            field: 'products',
            sortable: true,
        },
        {
            label: 'Last Update',
            field: 'lastUpdate',
            sortable: true,
        },
        {
            label: 'Status',
            field: 'status',
            sortable: true,
            render: (value, row, index) => (
                <div className={`flex items-center rounded-full ${data[index].isActive ? 'text-[#00B62A] bg-[#00B62A] bg-opacity-10' : 'text-[#FB0078] bg-[#FB0078] bg-opacity-10'} w-fit pr-3 py-1`}>
                    <LuDot size={20} />
                    <span>{value}</span>
                </div>
            )
        },
        {
            label: '',
            field: 'actions',
            sortable: false,
            render: (value, row, index) => (
                <div className="flex items-center gap-3 w-[42px]">
                    {data[index].isActive ? <FiEye size={18} onClick={() => toggleActive(index)} /> : <FiEyeOff size={18} onClick={() => toggleActive(index)} />}
                    <BsThreeDotsVertical size={18} />
                </div>
            )
        }
    ];

    return (
        <div className="catalogs-container flex-1 p-4 bg-[#1e252b] min-h-screen">
            <div className="flex items-center justify-between flex-wrap-reverse gap-4">
                <div className="flex space-x-5 px-2 items-center text-[14px] text-[#fafafa]">
                    <p>Showing catalogs from your database</p>
                </div>

                <div className="flex items-center text-[#a3a3a3] gap-3">
                    <FilterSelector />
                    <button className="flex items-center border border-[#a3a3a3] rounded-md gap-2 px-4 py-1 text-[14px]">
                        <BiPlus />
                        <span>Add New Catalog</span>
                    </button>
                </div>
            </div>

            <div className="flex flex-col bg-[#2d363d] rounded-lg p-3 mt-5">
                <div className="flex items-center justify-between">
                    <div className="flex space-x-3">
                        <p className='text-[14px] font-semibold'>Catalogs List</p>
                    </div>
                    <div className="flex space-x-5 items-center">
                        <Searchbar />
                    </div>
                </div>
                <div className='flex overflow-scroll scrollbar mt-5'>
                    <CustomTable columns={columns} data={data} />
                </div>
            </div>
        </div>
    );
};

export default MyCatalogs;
