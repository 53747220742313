import React from 'react'
import { Outlet } from 'react-router-dom'

const Dashboard = () => {
  return (
    <div className="dashboard-container flex-1 p-4 bg-[#1e252b] min-h-screen">
      <Outlet />
    </div>
  )
}

export default Dashboard
