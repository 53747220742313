import React from 'react';
import SuggestFeatureComponent from '../components/SuggestFeature/SuggestFeature';

const SuggestFeature = () => {
    return (
        <div className="suggest-feature-container">
            <SuggestFeatureComponent />
        </div>
    );
};

export default SuggestFeature; 