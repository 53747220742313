import React from 'react'
import { Outlet } from 'react-router-dom'
import RepricingRules from '../components/Repricing/RepricingRules'

const Repricing = () => {
  return (
    <div className="repricing-container flex-1 p-4 bg-[#1e252b] min-h-screen">
      <h1 className="text-2xl text-white mb-4">Repricing</h1>
      <RepricingRules />
      <Outlet />
    </div>
  )
}

export default Repricing 