import React from 'react';
import { Outlet } from 'react-router-dom';
import SettingHome from '../components/Settings/SettingHome';

const Settings = () => {
    return (
        <>
            <div className="flex items-center justify-between flex-wrap-reverse gap-4">
                <div className="flex space-x-5 px-2 items-center text-[14px] text-[#fafafa]">
                    <p>Settings</p>
                </div>
            </div>

            <div className="mt-5">
                <SettingHome />
            </div>

            <Outlet />
        </>
    );
};

export default Settings; 