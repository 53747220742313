import React from 'react'

const Organization = () => {
  const userRole = 'admin' // This should come from your auth context or state

  return (
    <div className="organization-container flex-1 p-4 bg-[#1e252b] min-h-screen">
      <h1 className="text-2xl text-white mb-4">Organization</h1>
      {userRole === 'admin' && (
        <div className="admin-content">
          {/* Add admin-specific content here */}
        </div>
      )}
    </div>
  )
}

export default Organization
