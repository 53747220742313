import React from 'react';
import ManageUsersHome from '../components/ManageUsers/ManageUsersHome';
import Profiles from '../components/ManageUsers/Profiles';

const ManageProfile = () => {
    return (
        <>
            <ManageUsersHome />
            <Profiles />
        </>
    );
};

export default ManageProfile; 