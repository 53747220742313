// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-select {
    background-color: #343E46;
    color: #a3a3a3;
    display: flex;
    max-width: 200px;
    height: 30px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 5px;
    flex-shrink: 0;
    padding: 2px 8px;
    font-size: 14px;
    border-radius: 9px;
    z-index: 10;
}

.custom-select option {
    background-color: #1E252B;
    color: #a3a3a3;
    padding: 4px 8px;
    margin-top: 2px;
    font-size: 14px;
}
`, "",{"version":3,"sources":["webpack://./src/components/SuggestFeature/styles.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,cAAc;IACd,aAAa;IACb,gBAAgB;IAChB,YAAY;IACZ,sBAAsB;IACtB,uBAAuB;IACvB,uBAAuB;IACvB,QAAQ;IACR,cAAc;IACd,gBAAgB;IAChB,eAAe;IACf,kBAAkB;IAClB,WAAW;AACf;;AAEA;IACI,yBAAyB;IACzB,cAAc;IACd,gBAAgB;IAChB,eAAe;IACf,eAAe;AACnB","sourcesContent":[".custom-select {\n    background-color: #343E46;\n    color: #a3a3a3;\n    display: flex;\n    max-width: 200px;\n    height: 30px;\n    flex-direction: column;\n    justify-content: center;\n    align-items: flex-start;\n    gap: 5px;\n    flex-shrink: 0;\n    padding: 2px 8px;\n    font-size: 14px;\n    border-radius: 9px;\n    z-index: 10;\n}\n\n.custom-select option {\n    background-color: #1E252B;\n    color: #a3a3a3;\n    padding: 4px 8px;\n    margin-top: 2px;\n    font-size: 14px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
