import React from 'react';
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button } from "@nextui-org/react";
import { IoClose } from "react-icons/io5";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const SelectDay = ({ isOpen, onClose }) => {
    const [selectedDate, setSelectedDate] = React.useState(new Date());

    return (
        <Modal 
            isOpen={isOpen} 
            onClose={onClose}
            classNames={{
                base: "bg-[#2D363D]",
                header: "border-b border-[#404B51]",
                body: "py-6",
                footer: "border-t border-[#404B51]"
            }}
        >
            <ModalContent>
                {(onClose) => (
                    <>
                        <ModalHeader className="flex flex-col gap-1">
                            <div className="flex items-center justify-between">
                                <h3 className="text-lg font-medium">Select Day</h3>
                                <Button isIconOnly variant="light" onClick={onClose}>
                                    <IoClose size={20}/>
                                </Button>
                            </div>
                        </ModalHeader>
                        <ModalBody>
                            <DatePicker
                                selected={selectedDate}
                                onChange={(date) => setSelectedDate(date)}
                                inline
                            />
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                onClick={onClose}
                                className="bg-transparent border border-[#a3a3a3] text-[#a3a3a3]"
                            >
                                Done
                            </Button>
                        </ModalFooter>
                    </>
                )}
            </ModalContent>
        </Modal>
    );
};

export default SelectDay;
