import React, { useState } from 'react';
import Searchbar from '../components/Searchbar';
import MassiveActionSelector from '../components/Dashboard/MassiveActionSelector';
import ExportSelector from '../components/Dashboard/ExportSelector';
import ProductInfo from '../components/MyProduct/ProductInfo';
import CompetitorTable from '../components/MyProduct/CompetitorTable';

const MyProduct = () => {
    const [selectedAction, setSelectedAction] = useState("Select an option");
    const [selectedExport, setSelectedExport] = useState("Select an option");

    return (
        <>
            <div className="flex items-center justify-between flex-wrap-reverse gap-4">
                <div className="flex space-x-5 px-2 items-center text-[14px] text-[#fafafa]">
                    <p>My Product</p>
                </div>

                <div className="flex items-center text-[#a3a3a3] gap-3">
                    <MassiveActionSelector selectedAction={selectedAction} setSelectedAction={setSelectedAction} />
                    <ExportSelector selectedExport={selectedExport} setSelectedExport={setSelectedExport} />
                </div>
            </div>
            <div className="">
                <div className="bg-[#2d363d] rounded-md p-5 mt-5">
                    <p className='mb-5 text-sm'>Please enter a page of your competitor's store which contains product information</p>
                    <div className='mb-[40px]'>
                        <Searchbar />
                    </div>
                    <div className="grid grid-cols-12 gap-8">
                        <ProductInfo />
                        <CompetitorTable />
                    </div>
                </div>
            </div>
        </>
    );
};

export default MyProduct;
