import React from 'react'
import { Routes, Route } from 'react-router-dom'
import ReportHome from '../components/Report/ReportHome'
import AddNewReport from '../components/Report/AddNewReport'

const Report = () => {
  return (
    <div className="report-container flex-1 p-4 bg-[#1e252b] min-h-screen">
      <Routes>
        <Route index element={<ReportHome />} />
        <Route path="addnew" element={<AddNewReport />} />
      </Routes>
    </div>
  )
}

export default Report 