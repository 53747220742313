// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.trending-selector [data-slot="listbox"] {
    display: flex;
    flex-direction: row;
    border: #a3a3a3;
  }

  .trending-selector [data-slot="listbox"] > [data-slot="listbox"] {
    gap: 0;
  }

  .trending-selector [data-slot="listbox"] > [data-slot="listbox"] > [data-slot="base"] {
    border-right: #a3a3a3 1px solid;
  }
`, "",{"version":3,"sources":["webpack://./src/components/SuggestFeature/TrendingSelector.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,eAAe;EACjB;;EAEA;IACE,MAAM;EACR;;EAEA;IACE,+BAA+B;EACjC","sourcesContent":[".trending-selector [data-slot=\"listbox\"] {\n    display: flex;\n    flex-direction: row;\n    border: #a3a3a3;\n  }\n\n  .trending-selector [data-slot=\"listbox\"] > [data-slot=\"listbox\"] {\n    gap: 0;\n  }\n\n  .trending-selector [data-slot=\"listbox\"] > [data-slot=\"listbox\"] > [data-slot=\"base\"] {\n    border-right: #a3a3a3 1px solid;\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
