import React from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import { NextUIProvider } from '@nextui-org/react'
import './App.css'
import Navbar from './components/Navbar'
import Dashboard from './pages/Dashboard'
import DashboardHome from './components/Dashboard/DashboardHome'
import Competitors from './pages/Competitors'
import Repricing from './pages/Repricing'
import Report from './pages/Report'
import MyCatalogs from './pages/MyCatalogs'
import MyProduct from './pages/MyProduct'
import ViewAll from './components/Dashboard/ViewAll'
import AddNew from './pages/AddNew'
import Settings from './pages/Settings'
import ManageUser from './pages/ManageUser'
import Organization from './pages/Organization'
import Payment from './pages/Payment'
import HelpCenter from './pages/HelpCenter'
import SuggestFeature from './pages/SuggestFeature'
import AlertHome from './pages/AlertHome'
import ManageProfile from './pages/ManageProfile'
import SettingsGeneral from './components/Settings/SettingsGeneral'
import SettingsCommPrefrence from './components/Settings/SettingsCommPrefrence'
import APIKeys from './components/Settings/APIKeys'
import Roadmap from './components/SuggestFeature/Roadmap'
import Feedback from './components/SuggestFeature/Feedback'
import Login from './views/pages/authentication/Login'

function App() {
  return (
    <NextUIProvider className="dark text-foreground bg-background">
      <BrowserRouter>
        <Routes>
          {/* Auth Routes - Outside Dashboard Layout */}
          <Route path="/login" element={<Login />} />
          
          {/* Protected Routes - Inside Dashboard Layout */}
          <Route path="/" element={<Navbar />}>
            <Route path="/" element={<Dashboard />}>
              <Route index element={<DashboardHome />} />
              <Route path="dashboard/view" element={<ViewAll />} />
              <Route path="competitors" element={<Competitors />} />
              <Route path="repricing" element={<Repricing />} />
              <Route path="report" element={<Report />} />
              <Route path="catalogs" element={<MyCatalogs />} />
              <Route path="myproduct" element={<MyProduct />} />
              <Route path="alert" element={<AlertHome />} />
              <Route path="addnew" element={<AddNew />} />
              <Route path="settings" element={<Settings />}>
                <Route index element={<SettingsGeneral />} />
                <Route path="communicationprefrence" element={<SettingsCommPrefrence />} />
                <Route path="APIkeys" element={<APIKeys />} />
              </Route>
              <Route path="manageuser" element={<ManageProfile />} />
              <Route path="organization" element={<Organization />} />
              <Route path="payment" element={<Payment />} />
              <Route path="helpcenter" element={<HelpCenter />} />
              <Route path="suggestfeature" element={<SuggestFeature />}>
                <Route index element={<Roadmap />} />
                <Route path="feedback" element={<Feedback />} />
              </Route>
              <Route path="manageprofile" element={<ManageProfile />} />
            </Route>
          </Route>

          {/* Redirect unknown routes to dashboard */}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </BrowserRouter>
    </NextUIProvider>
  )
}

export default App
